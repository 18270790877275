




































import { Component, Vue, Ref } from 'vue-property-decorator';
import QrScanner from 'qr-scanner';

import Loader from '@/shared/resources/components/Loader.vue';
import Popup from '@/shared/lib/support/popups/Popup';
import Alert from '@/shared/resources/components/Alert.vue';
import QRReaderConfirm from './QRReaderConfirm.vue';
import QRCodeModel from '@/modules/qr-codes/models/QRCodeModel';
import ApiErrorResponses from '@/core/api/errors/ApiErrorResponses';
import SideModal from '@/shared/resources/components/SideModal.vue';

@Component({
  components: {
    SideModal,
    Alert,
    Loader,
    QRReaderConfirm,
  },
})
export default class QRReader extends Vue {
  /**
   * Refs
   */
  @Ref() private readonly videoElement!: HTMLVideoElement;

  /**
   * Data
   */
  private qrScanner!: QrScanner;
  private qrCode: QRCodeModel | null = null;
  private hasCamera: boolean | null = null;
  private modalShown: boolean = false;
  private loading: boolean = false;
  private showBlink: boolean = false;

  /**
   * Display getters
   */
  private get displayReader(): boolean {
    return this.hasCamera !== false;
  }

  private get displayError(): boolean {
    return !!this.errorMessage;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  /**
   * Getters
   */
  private get errorMessage(): string | null {
    if (this.hasCamera === false) {
      return 'Nie znaleziono kamery w urządzeniu.';
    }

    return null;
  }

  /**
   * Lifecycle hooks
   */
  private async mounted() {
    await this.checkHasCamera();
    this.createQRScanner();

    // await new Promise((resolve: any) => setTimeout(resolve, 1000));
    // this.stopQRScanner();
    // this.handleScanResult({ data: 'MmJkMjM0MTgtMjk4Ni00MzA5LWFhMDktZmI2Njg1ZjBjMTE3XzIwMjItMDctMjggMDg6MTc6Mzc=' });
  }

  private destroyed() {
    this.stopQRScanner();
  }

  /**
   * Methods
   */
  private async checkHasCamera() {
    this.hasCamera = await QrScanner.hasCamera();
  }

  private createQRScanner() {
    const onDecode = (result: string) => {
      this.stopQRScanner();

      setTimeout(() => {
        this.handleScanResult(result);
      }, 1500);
    };

    this.qrScanner = new QrScanner(
      this.videoElement,
      onDecode,
      {
        onDecodeError: (error: any) => null,
        highlightScanRegion: true,
        maxScansPerSecond: 1,
      } as any,
    );

    this.qrScanner.start();
  }

  private async handleScanResult(result: any) {
    this.loading = true;

    try {
      this.qrCode = await QRCodeModel
        .apiExt()
        .param('id', result.data)
        .get();

      this.qrScanner.stop();
      this.showModal();
    } catch (e) {
      let errorMessage = 'Wystąpił nieznany błąd.';

      if (e instanceof ApiErrorResponses) {
        if (e.isNotFound()) {
          errorMessage = 'Nie znaleziono kodu';
        }
      }

      await Popup.error(errorMessage);
      this.startQRScanner();
    }

    this.loading = false;
  }

  private showModal() {
    this.modalShown = true;
  }

  private hideModal() {
    this.modalShown = false;
  }

  private startQRScanner() {
    // if (!this.hasCamera) {
    //   return;
    // }
    //
    // this.qrScanner.start();

    this.showBlink = false;
    this.videoElement.play();
    this.qrScanner.start();
  }

  private stopQRScanner() {
    // if (!this.qrScanner) {
    //   return;
    // }
    //
    // this.qrScanner.stop();

    this.showBlink = true;
    this.videoElement.pause();
  }

  /**
   * Handlers
   */
  private onModalHide() {
    this.hideModal();
    this.startQRScanner();
  }
}
